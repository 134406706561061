import React from "react";
import styled from "styled-components";
import { SplashText } from "../icons/text";

const SplashWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  width: 100vw;
  height: 100%;

  z-index: 1;
`;

const SplashInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const Splash = ({ hide, slide, disable }) => (
  <SplashWrapper slide={slide}>
    <SplashInnerWrapper hide={hide}>
      <SplashText hide={hide} />
    </SplashInnerWrapper>
  </SplashWrapper>
);

export default Splash;
