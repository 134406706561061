import React from "react";
import { Location } from "@reach/router";
import styled from "styled-components";
import { Normalize } from "styled-normalize";

// Components
import GlobalStyles from "../styles/globalStyles";
import App from "./app";
import DefaultSEO from "./default-seo";

const Container = styled.div`
  position: relative;

  display: flex;
  min-height: 100vh;
  flex-direction: column;

  width: 100%;
`;

const Layout = ({ children }) => (
  <Location>
    {({ location }) => {
      return (
        <Container location={location.pathname}>
          <Normalize />
          <GlobalStyles />
          <DefaultSEO location={location.pathname} />
          <App location={location} children={children} />
        </Container>
      );
    }}
  </Location>
);

export default Layout;
