import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { StaticQuery, graphql, Link } from "gatsby";
import Media from "react-media";

// Components
import DesktopMenu from "../navigation/desktop-menu";
import MobileMenu from "../navigation/mobile-menu";

const HeaderWrapper = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  padding: 0 40px;

  background-color: #e6ebe9;
  z-index: 200;

  & h2,
  & button {
    color: ${props => (props.isOpen ? `#000` : `#D7490C`)};

    font-size: 14px;
    line-height: 90px;
    letter-spacing: 0.15em;
    text-transform: uppercase;
    font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande",
      sans-serif;

    @media (max-width: 900px) {
      line-height: 60px;
    }
  }

  & h2 {
    order: 1;
    flex: ${props => (props.isOpen ? `auto` : 1)};

    @media (max-width: 900px) {
      order: 1;
    }

    @media (max-width: 767px) {
      flex: auto;
    }
  }

  .button-container {
    order: 3;
    flex: ${props => (props.isOpen ? `auto` : 1)};
    display: flex;

    @media (max-width: 900px) {
      order: 2;
    }

    & button {
      margin: 0 0 0 auto;
    }

    @media (max-width: 767px) {
      flex: auto;
    }
  }

  @media (max-width: 767px) {
    padding: 0 20px;
    height: auto;
  }
`;

const Nav = styled.nav`
  display: ${props => (props.isOpen ? `block` : `none`)};
  width: calc(100% - 170px - 170px);

  order: 2;

  @media (max-width: 900px) {
    order: 3;

    width: 100%;
  }

  & ol {
    margin: 0;
    padding: 0;

    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;

    // @media (max-width: 900px) {
    //   margin-top: -6px;
    // }

    & li {
      padding: 0 10px;
      color: #d7490c;

      &:hover {
        color: #000;
      }

      @media (max-width: 900px) {
        display: block;

        line-height: 1;

        padding: 0 0 24px 0;

        &:first-of-type {
          padding: 0 0 23px 0;
        }
        width: 100%;
      }
    }
  }

  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande", sans-serif;
`;

const PageTitle = styled.div`
  text-align: center;

  order: 2;
  flex: ${props => (props.isOpen ? `auto` : 1)};

  display: ${props => (props.isOpen ? `none` : `block`)};

  font-size: 14px;
  line-height: 32px;
  letter-spacing: 0.15em;
  text-transform: uppercase;

  font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande", sans-serif;

  @media (max-width: 840px) {
    display: none;
  }
`;

export const Header = ({ location, data }) => {
  const [windowWidth, setWindowWidth] = useState(768);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  useEffect(() => {
    if (typeof window !== `undefined`) {
      let getWindowWidth = window.innerWidth;
      setWindowWidth(getWindowWidth);
    }
  }, []);
  return (
    <HeaderWrapper isOpen={isMenuOpen}>
      <h2>
        <Link to={`/`}>The Seeking State</Link>
      </h2>

      <PageTitle isOpen={isMenuOpen}>
        {location.pathname.includes("pamphlets-for-culture") &&
          `Pamphlets For Culture`}
        {(location.pathname === "/events" ||
          location.pathname === "/events/") &&
          `Events`}
      </PageTitle>

      <Nav isOpen={isMenuOpen}>
        <ol>
          <li>
            <Link
              to={`/pamphlets-for-culture`}
              onClick={() => setIsMenuOpen(false)}
            >
              Pamphlets For Culture
            </Link>
          </li>
          <li>
            <Link to={`/events`} onClick={() => setIsMenuOpen(false)}>
              Events
            </Link>
          </li>
        </ol>
      </Nav>
      <div className="button-container">
        <button onClick={() => setIsMenuOpen(!isMenuOpen)}>Menu</button>
      </div>
    </HeaderWrapper>
  );
};
