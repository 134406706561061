import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import styled from "styled-components";

const FooterWrapper = styled.footer`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;

  padding: 0 40px 22px 40px;

  font-size: 12px;
  line-height: 44px;
  letter-spacing: 0.06em;

  & div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;

    margin: 0;

    & p {
      margin: 0 20px 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  @media (max-width: 830px) {
    padding: 0 40px 10px 40px;

    line-height: 20px;
  }

  @media (max-width: 767px) {
    padding: 0 20px 17px 20px;
  }

  & a:hover {
    color: #d7490c;
  }
`;

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      prismicFooter {
        data {
          left_column {
            html
          }
        }
      }
    }
  `);

  return (
    <FooterWrapper>
      <div
        dangerouslySetInnerHTML={{
          __html: data.prismicFooter.data.left_column.html,
        }}
      />
    </FooterWrapper>
  );
};

export default Footer;
