import React from "react";
import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
body {
  font-family: "CaslonDoric-Medium", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;
  font-style: normal;

  font-size: 18px;
  line-height: 20px;
  letter-spacing: 0.15px;
  font-feature-settings: "liga";

  text-shadow: 1px 1px 1px rgba(0, 0, 0, .004);
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -webkit-tap-highlight-color: transparent;

  color: #000;
  margin: 0;

  background-color: #E6EBE9;
}

input {
  border-radius: 0;
}

h1,h2,h3,h4,h5{
  font-family: "CaslonDoric-Bold", "Helvetica Neue", "Lucida Grande", sans-serif;
  font-weight: normal;

  margin: 0;
}


/* Title 1 */
h1{
  font-size: 23px;
  line-height: 34px;
  letter-spacing: 0.06em;

    @media (max-width: 1000px) {
      font-size: 20px;
      line-height: 28px;
    }
}

/* Title 2 */
h2 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 1 */
h3 {
  font-size: 30px;
  line-height: 32px;
  letter-spacing: 0.2px;
}

/* Heading 2 */
h4 {
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 0.2px;
}

/* Heading 3 */
h5 {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.3px;
}


/*--------------------------------------------------------------
  ## Media
  --------------------------------------------------------------*/

video,
img {
  width: 100%;
  margin: 0;

  display: block;
}

/*--------------------------------------------------------------
  ## Buttons
  --------------------------------------------------------------*/

  button{
    border:0;
    border-radius: 0;
    background-color: transparent;
    -webkit-appearance: none;

    cursor: pointer;

    padding: 0;

    &:focus,
    &:hover{
      outline: none;
    }
  }

/*--------------------------------------------------------------
  ## Links
  --------------------------------------------------------------*/
a {
  color: inherit;
  text-decoration: none;

  position: relative;
}

a:visited {
  color: inherit;
}

a:hover,
a:focus,
a:active {
  color: inherit;
}

a:hover,
a:active,
a:focus {
  outline: 0;
}

/*--------------------------------------------------------------
  ## Text
  --------------------------------------------------------------*/

p {
  margin: 1em 0;
}

ul, li{
  list-style: none;
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
  ## Box Sizing / Layout
  --------------------------------------------------------------*/

div {
  box-sizing: border-box;
}

/*--------------------------------------------------------------
  # Cookie Consent
  --------------------------------------------------------------*/


.cookie-consent-container{
  position:fixed;
  left:40px;
  bottom:40px !important;

  @media(max-width: 767px){
    left: 20px;
    bottom: 20px !important;
  }

  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.06em;

  align-tems:baseline;
  background:#E6EBE9;
  color:#D7490C;
  display:flex;
  flex-wrap:wrap;
  justify-content:space-between;


  padding:15px;
  width:100%;
  max-width:300px;
  z-index:999;

  @media(max-width: 767px){
  max-width:280px;
  }

  border: 1px solid #D7490C;

  & a {
    text-decoration: underline;
  }

  & button{
    color: #D7490C;
    font-size: 11px;
    letter-spacing: 0.06em;

    background-color: #E6EBE9;
    border: 1px solid #D7490C;


    margin: 15px 0 0 0;
    padding: 7px 10px;

    &:hover{
      background-color: #D7490C;
      color: #fff;
    }
  }
}

`;

export default GlobalStyles;
