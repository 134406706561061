import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { Helmet } from "react-helmet";

const DefaultSEO = ({ data, location }) => {
  const pageTitle = location.split("/")[1];
  const capitalizedPageTitle =
    pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1);

  const finalPageTitle =
    capitalizedPageTitle === ""
      ? "The Seeking State"
      : `${capitalizedPageTitle} – The Seeking State`;

  return (
    <Helmet
      title={`${finalPageTitle}`}
      meta={[
        {
          name: "description",
          content: `${data.prismicHomepage.data.seo_text}`,
        },
        {
          name: "keywords",
          content: `${data.prismicHomepage.data.seo_keywords}`,
        },
        {
          name: "og:image",
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
        {
          name: "og:image:secure_url",
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
        {
          name: "og:description",
          content: `${data.prismicHomepage.data.seo_text}`,
        },
        {
          name: "og:image:width",
          content: `1200`,
        },
        {
          name: "og:image:height",
          content: `630`,
        },
        {
          name: "og:locale",
          content: `en`,
        },
        {
          name: "twitter:title",
          content: `The Seeking State`,
        },
        {
          name: "twitter:description",
          content: `${data.prismicHomepage.data.seo_text}`,
        },
        {
          name: "twitter:card",
          content: `summary_large_image`,
        },
        {
          name: "twitter:image",
          content: `${data.prismicHomepage.data.seo_image.url}`,
        },
      ]}
    />
  );
};

export default props => (
  <StaticQuery
    query={graphql`
      {
        prismicHomepage {
          data {
            seo_keywords
            seo_text
            seo_image {
              url
            }
          }
        }
      }
    `}
    render={data => <DefaultSEO data={data} {...props} />}
  />
);
