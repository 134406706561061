import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import CookieConsent from "react-cookie-consent";
import { Link } from "gatsby";

// Components
import { Header } from "./header";
import Footer from "./footer";
import Splash from "./splash";

const Main = styled.main`
  flex: 1;
  padding: 0;
`;

const SplashWrapper = styled.div`
  position: fixed;

  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  cursor: pointer;

  z-index: 20000;

  background-color: #d7490c;

  transform: translateY(${props => (props.disable ? "-100%" : "0")});
  transition: 750ms transform ease;
`;

const App = ({ location, children }) => {
  const [hide, hideSplash] = useState(false);
  const [slide, slideUp] = useState(false);
  const [disable, disableSplash] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      hideSplash(true);
      slideUp(true);
      disableSplash(true);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (typeof window !== undefined) {
      if (hide === false) {
        document.body.style.overflow = "hidden";
      }

      if (hide === true) {
        document.body.style.overflow = "";
      }
    }
  }, [hide]);

  const triggerDisableSplash = () => {
    hideSplash(true);
    slideUp(true);
    disableSplash(true);
  };

  return (
    <>
      {location.pathname === "/" && (
        <SplashWrapper disable={disable} onClick={() => triggerDisableSplash()}>
          <Div100vh
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            }}
          >
            <Splash hide={hide} slide={slide} disable={disable} />
          </Div100vh>
        </SplashWrapper>
      )}

      <Header location={location} />
      <Main>{children}</Main>
      <Footer location={location} />

      <CookieConsent
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="gatsby-gdpr-google-analytics"
        containerClasses="cookie-consent-container"
        disableStyles={true}
      >
        This website uses cookies to enhance the user experience. Close this
        dialog to confirm you are happy with that, or find out more in our{" "}
        <Link to={`/privacy-policy`}>privacy policy</Link>.
      </CookieConsent>
    </>
  );
};

export default App;
